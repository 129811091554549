<template>
  <div class="dashboard-container">
    <CRow v-if="hasAnyModules">
      <CCol col="12" sm="12" lg="6">
        <CCard class="cta-card h-100">
          <CCardBody>
            <div class="cta-header">
              <CRow>
                <CCol>
                  <div class="ml-5 mr-5 mt-4">
                    <router-link :to="{ name: 'Rapid Insight' }"
                      ><CImg
                        :src="$brand('modios-rapid-insight.png', '/img')"
                        class="mr-5"
                        height="45"
                    /></router-link>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <p class="h3 ml-5 mr-5 mt-2">
                    See inside your tax engine like never before.
                  </p>
                </CCol>
              </CRow>
            </div>
            <CRow style="margin-top: auto; margin-bottom: auto">
              <CCol>
                <div class="mb-5 mr-5 ml-5">
                  <p class="h5">
                    <strong>Quickly</strong> see and document the configurations
                    of your tax engine by exporting a configuration file from
                    the tax engine, uploading it into this utility, and
                    selecting your output preference
                    <em>(a multitab Excel workbook or a text file)</em>
                  </p>
                </div>
              </CCol>
            </CRow>
            <div class="ml-5 mr-5" style="margin-top: auto; margin-bottom: 0">
              <CButton
                v-if="rapidInsight"
                class="mb-3 card-action-button"
                color="info"
                block
                size="lg"
                shape="square"
                :to="{ name: 'Rapid Insight' }"
                ><strong>Run Now</strong></CButton
              >
              <CButton
                v-else
                class="mb-3 card-action-button"
                color="info"
                block
                size="lg"
                shape="square"
                @click="contactToUpgrade"
                ><strong>Contact to Upgrade</strong></CButton
              >
            </div>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol col="12" sm="12" lg="6">
        <CCard class="cta-card h-100">
          <CCardBody>
            <div class="cta-header">
              <CRow>
                <CCol>
                  <div class="ml-5 mr-5 mt-4">
                    <router-link :to="{ name: 'Testing' }"
                      ><CImg
                        :src="$brand('modios-rapid-testing.png', '/img')"
                        height="45"
                    /></router-link>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <p class="h3 mr-5 ml-5 mt-2">
                    Unparalleled testing capabilities.
                  </p>
                </CCol>
              </CRow>
            </div>
            <CRow style="margin-top: auto; margin-bottom: auto">
              <CCol>
                <div class="mb-5 mr-5 ml-5">
                  <p class="h5">
                    <strong>Easily</strong> run unlimited test transactions
                    against your target tax engine and automatically compare the
                    actual tax calculation results to the expected results in
                    order to validate that your tax engine is delivering the
                    results that you need
                  </p>
                </div>
              </CCol>
            </CRow>
            <div class="ml-5 mr-5" style="margin-top: auto; margin-bottom: 0">
              <CButton
                v-if="rapidTesting"
                class="mb-3 card-action-button"
                color="info"
                block
                size="lg"
                shape="square"
                :to="{ name: 'Testing' }"
                ><strong>Start Testing</strong></CButton
              >
              <CButton
                v-else
                class="mb-3 card-action-button"
                color="info"
                block
                size="lg"
                shape="square"
                @click="contactToUpgrade"
                ><strong>Contact to Upgrade</strong></CButton
              >
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow v-if="hasAnyModules" class="my-4">
      <CCol col="12" sm="12" lg="6">
        <CCard class="cta-card h-100">
          <CCardBody>
            <div class="cta-header">
              <CRow>
                <CCol>
                  <div class="ml-5 mr-5 mt-4">
                    <router-link :to="{ name: 'RapidVerifyDashboard' }"
                      ><CImg
                        :src="$brand('modios-rapid-verify.png', '/img')"
                        height="45"
                    /></router-link>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <p class="h3 mr-5 ml-5 mt-2">Trust your registration data.</p>
                </CCol>
              </CRow>
            </div>
            <CRow style="margin-top: auto; margin-bottom: auto">
              <CCol>
                <div class="mb-5 mr-5 ml-5">
                  <p class="h5">
                    <strong>Assess</strong> the accuracy of your registration
                    data with tax ID checks and extended verification for
                    supported countries. Upload your tax registration
                    information for batch processing or do realtime quick-check.
                    <br />
                    <br />
                  </p>
                </div>
              </CCol>
            </CRow>
            <div class="ml-5 mr-5" style="margin-top: auto; margin-bottom: 0">
              <CButton
                v-if="rapidVerify"
                class="mb-3 card-action-button"
                color="info"
                block
                size="lg"
                shape="square"
                :to="{ name: 'RapidVerifyDashboard' }"
                ><strong>Get Started</strong></CButton
              >
              <CButton
                v-else
                class="mb-3 card-action-button"
                color="info"
                block
                size="lg"
                shape="square"
                @click="contactToUpgrade"
                ><strong>Contact to Upgrade</strong></CButton
              >
            </div>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol col="12" sm="12" lg="6">
        <CCard class="cta-card h-100">
          <CCardBody>
            <div class="cta-header">
              <CRow>
                <CCol>
                  <div class="ml-5 mr-5 mt-4">
                    <a href="/pma/">
                      <CImg
                        :src="
                          $brand(
                            'modios-product-mapping-accelerator.png',
                            '/img',
                          )
                        "
                        height="45"
                      />
                    </a>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <p class="h3 mr-5 ml-5 mt-2">
                    Let AI Guide your product mappings.
                  </p>
                </CCol>
              </CRow>
            </div>
            <CRow style="margin-top: auto; margin-bottom: auto">
              <CCol>
                <div class="mb-5 mr-5 ml-5">
                  <p class="h5">
                    <strong>Utilize</strong> our proprietary Artificial
                    Intelligence to determine the best match for your products
                    or or commodities to the tax category trees utilized by your
                    tax engine or UNSPSC. Increase accuracy of mappings,
                    substantially reduce time and effort, and minimize
                    headaches.
                  </p>
                </div>
              </CCol>
            </CRow>
            <div class="ml-5 mr-5" style="margin-top: auto; margin-bottom: 0">
              <CButton
                v-if="pma"
                class="mb-3 card-action-button"
                color="info"
                block
                size="lg"
                shape="square"
                href="/pma/"
                ><strong>Let's Map</strong></CButton
              >
              <CButton
                v-else
                class="mb-3 card-action-button"
                color="info"
                block
                size="lg"
                shape="square"
                @click="contactToUpgrade"
                ><strong>Contact to Upgrade</strong></CButton
              >
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CCard v-if="loggedIn && !hasAnyModules">
      <CCardBody>
        <h1>No active modules</h1>
        <p>
          Please contact your administrator to request access to MODIOS Pulse
          modules
        </p>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Dashboard',
  computed: {
    ...mapGetters('auth', [
      'rapidInsight',
      'rapidTesting',
      'rapidVerify',
      'pma',
      'loggedIn',
    ]),
    hasAnyModules() {
      return (
        this.rapidInsight || this.rapidTesting || this.rapidVerify || this.pma
      );
    },
  },
  methods: {
    contactToUpgrade() {
      window.open(
        'https://www.cordiance.com/contact-us?utm_campaign=uinotifications&utm_medium=pulsedashboard&utm_source=pulse',
      );
    },
  },
};
</script>
